import { CollectionType } from '../controllers/subscription-service/types';

export const collectionTypeFlavorTextMap = (key: CollectionType) => {
  const textMap: Record<CollectionType, string> = {
    criteo_retail_media: 'The ability to collect advertising data from Criteo Retail Media',
    demand_side_platform: 'Advertising data for Amazon product placements served outside of Amazon\'s retail site',
    home_depot_advertising: 'Search and banner advertising data for ads displayed on Home Depot\'s retail site',
    home_depot_pos: 'Sales analytics data from Home Depot\'s vendor to retailer sales platform',
    lowes_pos: 'Sales analytics data from Lowe\'s vendor to retailer sales platform',
    marshall_omnichannel_advertising: 'Views into Omnichannel Advertising data',
    marshall_omnichannel_promotions: 'Views into Omnichannel Promotions data',
    meijer_pos: 'Sales analytics data from Meijer\'s vendor to retailer sales platform',
    product_page: 'Includes retailer pricing, star rating, digital content, etc. for items being tracked',
    product_seller_info: 'Obtains seller pricing information for all sellers of tracked items',
    product_reviews: 'Includes retailer\'s individual product reviews, their associated ratings, and contents',
    retailer_search_result: 'Provides access to Share of Voice collection and analytics',
    seller_central_pos: 'Sales analytics data from Amazon\'s marketplace seller to retailer sales platform - Seller Central',
    search_advertising: 'Search and banner advertising data for ads displayed on Amazon\'s retail sites',
    target_plus_3p_pos: 'Sales analytics data from Target Plus',
    target_pos: 'Sales analytics data from Target\'s vendor to retailer sales platform',
    target_roundel_advertising: 'Advertising data for ads displayed on Target\'s retail site',
    vendor_central_pos: 'Sales analytics data from Amazon\'s vendor to retailer sales platform - Vendor Central',
    walmart_connect_advertising: 'The ability to collect advertising data from Walmart Connect Advertising',
    walmart_luminate_pos: 'Sales analytics data from Walmart\'s 1P Luminate platform',
    walmart_pos: 'Sales analytics data from Walmart\'s 3P Seller Center platform',
    wayfair_pos: 'Sales analytics data from Wayfair\'s vendor to retailer sales platform',
    wayfair_search_advertising: 'Search and banner advertising data for ads displayed on Wayfair\'s retail sites',

  };
  if (key in textMap) {
    return textMap[key];
  }
  return `No Flavor Text for ${key}`;
};

export const CollectionTypeTextMap: { [key in CollectionType]: string } = {
  criteo_retail_media: 'Criteo Retail Media',
  demand_side_platform: 'Amazon Demand Side Platform',
  home_depot_advertising: 'Home Depot Advertising',
  home_depot_pos: 'Home Depot POS',
  lowes_pos: 'Lowe\'s POS',
  marshall_omnichannel_advertising: 'Omnichannel Advertising',
  marshall_omnichannel_promotions: 'Omnichannel Promotions',
  meijer_pos: 'Meijer POS',
  product_page: 'Product Page',
  product_seller_info: 'Product Seller Information',
  product_reviews: 'Product Reviews',
  retailer_search_result: 'Share of Voice',
  search_advertising: 'Amazon Search Advertising',
  seller_central_pos: 'Amazon Seller Central POS',
  target_roundel_advertising: 'Target Roundel Advertising',
  target_plus_3p_pos: 'Target Plus 3P POS',
  target_pos: 'Target POS',
  vendor_central_pos: 'Amazon Vendor Central POS',
  walmart_connect_advertising: 'Walmart Connect Advertising',
  walmart_luminate_pos: 'Walmart 1P POS',
  walmart_pos: 'Walmart 3P POS',
  wayfair_pos: 'Wayfair POS',
  wayfair_search_advertising: 'Wayfair Search Advertising',
};

export const collectionTypeFriendlyMap = (key: CollectionType) => {
  if (key in CollectionTypeTextMap) {
    return CollectionTypeTextMap[key];
  }
  return key;
};

export default collectionTypeFlavorTextMap;
