import { UserGrant } from '../controllers/auth/types';
import { CollectionType } from '../controllers/subscription-service/types';

export type LeftNavView = {
  report_name: string;
  module: string;
  friendly_name: string;
  breadcrumb: string;
  path: string;
  display_order: number;
  is_visible: boolean;
  required_collection_types: CollectionType[];
  valid_permissions: UserGrant[];
};

export const masterViewList: LeftNavView[] = [
  {
    report_name: 'skuDetails',
    module: 'base',
    friendly_name: 'SKU Details',
    breadcrumb: 'SKU Details',
    path: 'skuDetails',
    display_order: 1,
    is_visible: false,
    required_collection_types: [
      'product_page',
      'product_seller_info',
      'product_reviews',
      'vendor_central_pos',
      'seller_central_pos',
      'meijer_pos',
      'search_advertising',
      'demand_side_platform',
      'retailer_search_result',
    ],
    valid_permissions: ['MEMBER'],
  },
  {
    report_name: 'availabilityOverview',
    module: 'availability',
    friendly_name: 'Availability Overview',
    breadcrumb: 'Product Monitoring -> Availability -> Availability Overview',
    path: 'products/availability/availability_overview',
    display_order: 1120,
    is_visible: true,
    required_collection_types: ['product_page', 'product_seller_info'],
    valid_permissions: ['AVAILABILITY_VIEWER'],
  },
  {
    report_name: 'availabilityHistory',
    module: 'availability',
    friendly_name: 'Availability History',
    breadcrumb: 'Product Monitoring -> Availability -> Availability History',
    path: 'products/availability/availability_history',
    display_order: 1140,
    is_visible: true,
    required_collection_types: ['product_page'],
    valid_permissions: ['AVAILABILITY_VIEWER'],
  },
  {
    report_name: 'contentOverview',
    module: 'content',
    friendly_name: 'Content Overview',
    breadcrumb: 'Product Monitoring -> Content -> Content Overview',
    path: 'products/content/content_overview',
    display_order: 1420,
    is_visible: true,
    required_collection_types: ['product_page', 'product_seller_info'],
    valid_permissions: ['CONTENT_VIEWER'],
  },
  {
    report_name: 'mostCommon3pSellers',
    module: 'marketplace',
    friendly_name: 'Most Common 3P Sellers',
    breadcrumb: 'Product Monitoring -> Marketplace -> Most Common 3P Sellers',
    path: 'products/marketplace/most_common_3p_sellers',
    display_order: 1520,
    is_visible: true,
    required_collection_types: ['product_page', 'product_seller_info'],
    valid_permissions: ['MARKETPLACE_VIEWER'],
  },
  {
    report_name: 'retailerCategoryRankings',
    module: 'marketplace',
    friendly_name: 'Category Rankings',
    breadcrumb: 'Product Monitoring -> Marketplace -> Category Rankings',
    path: 'products/marketplace/retailer_category_rankings',
    display_order: 1540,
    is_visible: true,
    required_collection_types: ['product_page'],
    valid_permissions: ['MARKETPLACE_VIEWER'],
  },
  {
    report_name: 'pricingOverview',
    module: 'pricing',
    friendly_name: 'Pricing Overview',
    breadcrumb: 'Product Monitoring -> Pricing -> Pricing Overview',
    path: 'products/pricing/pricing_overview',
    display_order: 1220,
    is_visible: true,
    required_collection_types: ['product_page'],
    valid_permissions: ['PRICING_VIEWER'],
  },
  {
    report_name: 'pricingHistory',
    module: 'pricing',
    friendly_name: 'Pricing History',
    breadcrumb: 'Product Monitoring -> Pricing -> Pricing History',
    path: 'products/pricing/pricing_history',
    display_order: 1240,
    is_visible: true,
    required_collection_types: ['product_page'],
    valid_permissions: ['PRICING_VIEWER'],
  },
  {
    report_name: 'mapOverview',
    module: 'pricing',
    friendly_name: 'MAP Overview',
    breadcrumb: 'Product Monitoring -> Pricing -> MAP Overview',
    path: 'products/pricing/map_overview',
    display_order: 1260,
    is_visible: true,
    required_collection_types: ['product_page'],
    valid_permissions: ['PRICING_VIEWER'],
  },
  {
    report_name: 'reviewsOverview',
    module: 'reviews',
    friendly_name: 'Reviews Overview',
    breadcrumb: 'Product Monitoring -> Reviews -> Reviews Overview',
    path: 'products/review/reviews_overview',
    display_order: 1320,
    is_visible: true,
    required_collection_types: ['product_reviews'],
    valid_permissions: ['REVIEW_VIEWER'],
  },
  {
    report_name: 'reviewDetails',
    module: 'reviews',
    friendly_name: 'Review Details',
    breadcrumb: 'Product Monitoring -> Reviews -> Review Details',
    path: 'products/review/review_details',
    display_order: 1340,
    is_visible: true,
    required_collection_types: ['product_reviews'],
    valid_permissions: ['REVIEW_VIEWER'],
  },
  {
    report_name: 'amazonVendorCentralPos',
    module: 'vendor_central_pos',
    friendly_name: 'Amazon VC POS',
    breadcrumb: 'Sales & Advertising -> Sales -> Amazon VC POS',
    path: 'sales_and_advertising/sales/amazon_vc_pos',
    display_order: 2120,
    is_visible: true,
    required_collection_types: ['vendor_central_pos'],
    valid_permissions: ['AMAZON_POS_VIEWER'],
  },
  {
    report_name: 'amazonSellerCentralPos',
    module: 'seller_central_pos',
    friendly_name: 'Amazon SC POS',
    breadcrumb: 'Sales & Advertising -> Sales -> Amazon SC POS',
    path: 'sales_and_advertising/sales/amazon_sc_pos',
    display_order: 2140,
    is_visible: true,
    required_collection_types: ['seller_central_pos'],
    valid_permissions: ['AMAZON_POS_VIEWER'],
  },
  {
    report_name: 'meijerPos',
    module: 'meijer_pos',
    friendly_name: 'Meijer POS',
    breadcrumb: 'Sales & Advertising -> Sales -> Meijer POS',
    path: 'sales_and_advertising/sales/meijer_pos',
    display_order: 2170,
    is_visible: true,
    required_collection_types: ['meijer_pos'],
    valid_permissions: ['MEIJER_POS_VIEWER'],
  },
  {
    report_name: 'targetPos',
    module: 'target_pos',
    friendly_name: 'Target POS',
    breadcrumb: 'Sales & Advertising -> Sales -> Target POS',
    path: 'sales_and_advertising/sales/target_pos',
    display_order: 2172,
    is_visible: true,
    required_collection_types: ['target_pos'],
    valid_permissions: ['TARGET_POS_VIEWER'],
  },
  {
    report_name: 'amazonAdvertisingSearch',
    module: 'search_advertising',
    friendly_name: 'Amazon Advertising',
    breadcrumb: 'Sales & Advertising -> Advertising -> Amazon Advertising',
    path: 'sales_and_advertising/advertising/amazon_advertising',
    display_order: 2220,
    is_visible: true,
    required_collection_types: ['search_advertising'],
    valid_permissions: ['AMAZON_AD_VIEWER'],
  },
  {
    report_name: 'amazonAdvertisingDsp',
    module: 'demand_side_platform',
    friendly_name: 'Amazon DSP',
    breadcrumb: 'Sales & Advertising -> Advertising -> Amazon DSP',
    path: 'sales_and_advertising/advertising/amazon_dsp',
    display_order: 2230,
    is_visible: true,
    required_collection_types: ['demand_side_platform'],
    valid_permissions: ['AMAZON_AD_VIEWER'],
  },
  {
    report_name: 'homeDepotAdvertisingSearch',
    module: 'home_depot_advertising',
    friendly_name: 'Home Depot Advertising',
    breadcrumb: 'Sales & Advertising -> Advertising -> Home Depot Advertising',
    path: 'sales_and_advertising/advertising/home_depot_advertising',
    display_order: 2250,
    is_visible: true,
    required_collection_types: ['home_depot_advertising'],
    valid_permissions: ['HOME_DEPOT_AD_VIEWER'],
  },
  {
    report_name: 'walmartLuminateEcommPos',
    module: 'walmart_luminate',
    friendly_name: 'Walmart 1P POS',
    breadcrumb: 'Sales & Advertising -> Sales -> Walmart 1P POS',
    path: 'sales_and_advertising/sales/walmart_luminate',
    display_order: 2173,
    is_visible: true,
    required_collection_types: ['walmart_luminate_pos'],
    valid_permissions: ['WALMART_POS_VIEWER'],
  },
  {
    report_name: 'walmartSellerCenterPos',
    module: 'walmart_pos',
    friendly_name: 'Walmart 3P POS',
    breadcrumb: 'Sales & Advertising -> Sales -> Walmart 3P POS',
    path: 'sales_and_advertising/sales/walmart_pos',
    display_order: 2175,
    is_visible: true,
    required_collection_types: ['walmart_pos'],
    valid_permissions: ['WALMART_POS_VIEWER'],
  },
  {
    report_name: 'walmartOmniPos',
    module: 'walmart_pos',
    friendly_name: 'Walmart Omni POS',
    breadcrumb: 'Sales & Advertising -> Sales -> Walmart Omni POS',
    path: 'sales_and_advertising/sales/walmart_omni_pos',
    display_order: 2177,
    is_visible: true,
    required_collection_types: ['walmart_luminate_pos', 'walmart_pos'],
    valid_permissions: ['WALMART_POS_VIEWER'],
  },
  {
    report_name: 'bestBuyAdvertisingSearch',
    module: 'best_buy_advertising',
    friendly_name: 'Best Buy Advertising',
    breadcrumb: 'Sales & Advertising -> Advertising -> Best Buy Advertising',
    path: 'sales_and_advertising/advertising/best_buy_advertising',
    display_order: 2240,
    is_visible: true,
    required_collection_types: ['criteo_retail_media'],
    valid_permissions: ['BEST_BUY_AD_VIEWER'],
  },
  {
    report_name: 'homeDepotAdvertisingSearch',
    module: 'home_depot_advertising',
    friendly_name: 'Home Depot Advertising',
    breadcrumb: 'Sales & Advertising -> Advertising -> Home Depot Advertising',
    path: 'sales_and_advertising/advertising/home_depot_advertising',
    display_order: 2250,
    is_visible: true,
    required_collection_types: ['home_depot_advertising'],
    valid_permissions: ['HOME_DEPOT_AD_VIEWER'],
  },
  {
    report_name: 'lowesAdvertisingSearch',
    module: 'lowes_advertising',
    friendly_name: 'Lowe\'s Advertising',
    breadcrumb: 'Sales & Advertising -> Advertising -> Lowe\'s Advertising',
    path: 'sales_and_advertising/advertising/lowes_advertising',
    display_order: 2260,
    is_visible: true,
    required_collection_types: ['criteo_retail_media'],
    valid_permissions: ['LOWES_AD_VIEWER'],
  },
  {
    report_name: 'macysAdvertisingSearch',
    module: 'macys_advertising',
    friendly_name: "Macy's Advertising",
    breadcrumb: "Sales & Advertising -> Advertising -> Macy's Advertising",
    path: 'sales_and_advertising/advertising/macys_advertising',
    display_order: 2270,
    is_visible: true,
    required_collection_types: ['criteo_retail_media'],
    valid_permissions: ['MACYS_AD_VIEWER'],
  },
  {
    report_name: 'skaiOmnichannel',
    module: 'search_advertising',
    friendly_name: 'Omnichannel Advertising',
    breadcrumb: 'Sales & Advertising -> Advertising -> Omnichannel Advertising',
    path: 'sales_and_advertising/advertising/omnichannel_advertising',
    display_order: 2275,
    is_visible: true,
    required_collection_types: ['marshall_omnichannel_advertising'],
    valid_permissions: ['MARSHALL_OMNICHANNEL_AD_VIEWER'],
  },
  {
    report_name: 'targetAdvertisingSearch',
    module: 'target_advertising',
    friendly_name: 'Target Advertising',
    breadcrumb: 'Sales & Advertising -> Advertising -> Target Advertising',
    path: 'sales_and_advertising/advertising/target_advertising',
    display_order: 2280,
    is_visible: true,
    required_collection_types: ['criteo_retail_media'],
    valid_permissions: ['TARGET_AD_VIEWER'],
  },
  {
    report_name: 'walmartAdvertisingSearch',
    module: 'walmart_connect_advertising',
    friendly_name: 'Walmart Advertising',
    breadcrumb: 'Sales & Advertising -> Advertising -> Walmart Advertising',
    path: 'sales_and_advertising/advertising/walmart_advertising_search',
    display_order: 2285,
    is_visible: true,
    required_collection_types: ['walmart_connect_advertising'],
    valid_permissions: ['WALMART_AD_VIEWER'],
  },
  {
    report_name: 'wayfairAdvertising',
    module: 'wayfair_advertising',
    friendly_name: 'Wayfair Advertising',
    breadcrumb: 'Sales & Advertising -> Advertising -> Wayfair Advertising',
    path: 'sales_and_advertising/advertising/wayfair_advertising',
    display_order: 2290,
    is_visible: true,
    required_collection_types: ['wayfair_search_advertising'],
    valid_permissions: ['WAYFAIR_AD_VIEWER'],
  },
  {
    report_name: 'marshallOmnichannelPromotions',
    module: 'omnichannel_promotions',
    friendly_name: 'Omnichannel Promotions',
    breadcrumb: 'Sales & Advertising -> Promotions -> Omnichannel Promotions',
    path: 'sales_and_advertising/promotions/omnichannel_promotions',
    display_order: 2375,
    is_visible: true,
    required_collection_types: ['marshall_omnichannel_promotions'],
    valid_permissions: ['MARSHALL_OMNICHANNEL_PROMO_VIEWER'],
  },
  {
    report_name: 'shareOfVoiceOverview',
    module: 'share_of_voice',
    friendly_name: 'SOV Overview',
    breadcrumb: 'Share of Voice -> SOV Overview',
    path: 'share_of_voice/overview',
    display_order: 3120,
    is_visible: true,
    required_collection_types: ['retailer_search_result'],
    valid_permissions: ['SOV_VIEWER', 'SOV_ADMIN'],
  },
];

export const masterViewFlavorMap = (key: string) => {
  const textMap: { [key: string]: string } = {
    'Amazon Advertising':
      'See your Amazon search advertising performance all in one place',
    'Amazon DSP':
      'See your Amazon Demand Side Platform data all in one place',
    'Amazon SC POS': 'See your Amazon Seller Central sales performance all in one place',
    'Amazon VC POS': 'See your Amazon Vendor Central sales performance all in one place',
    'Amazon VC-SC Hybrid POS': 'An integrated view of Amazon Seller Central and Vendor Central performance',
    'Availability Overview':
      "See your items' availability statuses and lost buy box trends over time and on a selected date",
    'Best Buy Advertising':
      'See your Best Buy search advertising performance all in one place',
    'Category Rankings':
      "See your items' sales velocity rankings as provided by retailers",
    'Content Overview':
      "See your items' content as it appears live on retailer sites",
    'Content Evaluation':
      "See your items' content scores and find facets requiring improvement",
    'Home Depot Advertising':
      'See your Home Depot search advertising performance all in one place',
    'Home Depot POS': 'See your Home Depot sales performance all in one place',
    'Lowe\'s Advertising':
      'See your Lowe\'s search advertising performance all in one place',
    'Lowe\'s POS': 'See your Lowe\'s sales performance all in one place',
    "Macy's Advertising":
      "See your Macy's search advertising performance all in one place",
    'MAP Overview':
      'See MAP compliance across your retail channels. Track violation trends over time.',
    'Meijer POS': 'See your Meijer sales performance all in one place',
    'Most Common 3P Sellers':
      "See third party sellers offering your items and whether they're winning the buy box",
    'Omnichannel Advertising':
      'See a summary of your advertising performance across channels',
    'Omnichannel Promotions':
      "See your promotions' performance all in one place",
    'Pricing Overview':
      "See your items' prices and trends over time and on a selected date",
    'Pricing History':
      "See your items' historical pricing trends and export a complete history by day",
    'Review Details':
      "Easily navigate your items' review content and see review velocity trends over time",
    'Reviews Overview':
      "See your items' rating and review counts and related trends over time and on a selected date",
    'Seller Central POS':
      'See your Amazon Seller Central sales performance all in one place',
    'Share of Voice': 'Analyze marketshare for your brand and products',
    'SOV Overview': 'Analyze your items search placement on retailer sites',
    'SKU Details': 'Provides in-depth data on items',
    'Target Advertising':
      'See your Target advertising performance all in one place',
    'Target POS': 'See your Target sales performance all in one place',
    'VC + SC Hybrid POS':
      'See your performance on Amazon Vendor and Seller Central platforms in one place',
    'Vendor Central POS':
      'See your Amazon Vendor Central sales performance all in one place',
    'Walmart 1P POS': 'See your Walmart 1P sales performance all in one place',
    'Walmart 3P POS': 'See your Walmart 3P sales performance all in one place',
    'Walmart Omni POS': 'See your Walmart Omni sales performance all in one place',
    'Wayfair POS': 'See your Wayfair sales performance all in one place',
    'Wayfair Advertising':
      'See your Wayfair advertising performance all in one place',
  };
  if (key in textMap) {
    return textMap[key];
  }
  return key;
};
